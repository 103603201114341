import { PageTransition } from "@steveeeie/react-page-transition";
import { useContext } from "react";
import { Route, Routes as Switch, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Meet from "./pages/Meet";
import Notifications from "./pages/Notifications";
import Profile, { ProfileContents, ProfileView } from "./pages/Profile";
import RecoverPassword from "./pages/RecoverPassword";
import Register from "./pages/Register";
import Reservations from "./pages/Reservations";
import Setting, {
  SettingAddPhoto,
  SettingHome,
  SettingUpdateDescription,
  SettingUpdateEmail,
  SettingUpdateInstagram,
  SettingUpdateName,
  SettingUpdatePassword,
  SettingUpdatePhoto,
  SettingUpdateProfile,
  SettingUpdateTiktok,
  SettingUpdateTwitter,
  SettingUpdateUsername,
  SettingUpdateWhatsapp,
} from "./pages/Setting";
import { EffectContext } from "./providers/effect";
import AdminRoutes from "./pages/Admin/Routes";
import Admin from "./pages/Admin";
import Party, { PartyView } from "./pages/Party";

const Routes = () => {
  let location = useLocation();

  const { preset } = useContext(EffectContext);

  return (
    <PageTransition preset={preset} transitionKey={location.pathname}>
      <Switch location={location}>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/recover-password" element={<RecoverPassword />} />

        <Route path="/reservations" element={<Reservations />} />
        <Route path="/meet" element={<Meet />} />
        <Route path="/notifications" element={<Notifications />} />

        <Route path="/logout" element={<Logout />} />

        <Route path="settings" element={<Setting />}>
          <Route index element={<SettingHome />} />
          <Route path="update-email" element={<SettingUpdateEmail />} />
          <Route path="update-password" element={<SettingUpdatePassword />} />
          <Route path="update-profile" element={<SettingUpdateProfile />} />
          <Route path="update-name" element={<SettingUpdateName />} />
          <Route path="update-instagram" element={<SettingUpdateInstagram />} />
          <Route path="update-tiktok" element={<SettingUpdateTiktok />} />
          <Route path="update-twitter" element={<SettingUpdateTwitter />} />
          <Route path="update-whatsapp" element={<SettingUpdateWhatsapp />} />
          <Route path="update-photo" element={<SettingUpdatePhoto />} />
          <Route path="user-photos" element={<SettingAddPhoto />} />
          <Route
            path="update-description"
            element={<SettingUpdateDescription />}
          />
          <Route path="update-username" element={<SettingUpdateUsername />} />
        </Route>

        <Route path="/admin/*" element={<AdminRoutes />} />

        <Route path="@:username" element={<Profile />}>
          <Route index element={<ProfileView />} />
          <Route path="contents" element={<ProfileContents />} />
        </Route>
        <Route path="u/:id" element={<Profile />}>
          <Route index element={<ProfileView />} />
          <Route path="contents" element={<ProfileContents />} />
        </Route>

        <Route path=":username" element={<Party />}>
          <Route index element={<PartyView />} />
        </Route>
      </Switch>
    </PageTransition>
  );
};

export default Routes;
