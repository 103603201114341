import React from "react";
import AvatarMui from "@mui/material/Avatar";
import { Box } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const Avatar = ({ url, alt }) => {
  return (
    <Box
      sx={{
        width: "100px",
        height: "100px",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <AvatarMui
        alt={alt}
        src={url}
        sx={{
          width: "100px",
          height: "100px",
          position: "relative",
        }}
      />

      <Box
        sx={{
          width: "100px",
          height: "100px",
          cursor: "pointer",
          position: "absolute",
          borderRadius: "50%",
          top: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AddAPhotoIcon />
      </Box>
    </Box>
  );
};

export default Avatar;
