import { Box, Container, Divider, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import Avatar from "../../components/atoms/Avatar";
import EditableMenu from "../../components/molecules/EditableMenu";
import { AppContext } from "../../providers/app";
import { EffectContext } from "../../providers/effect";
import { maskPhone } from "../../utils/string";

const UpdateProfile = () => {
  const { setPageTitle, user } = useContext(AppContext);
  useEffect(() => setPageTitle("Editar perfil"), [setPageTitle]);
  const { navigateEffect } = useContext(EffectContext);

  if (!user) {
    return <></>;
  }

  const list = [
    {
      label: "Nome",
      value: user.displayName,
      path: "settings/update-name",
    },
    {
      label: "Nome de usuário",
      value: user.username ? user.username : null,
      path: "settings/update-username",
      helperText: "Adicione",
    },
    {
      label: null,
      value: `letsfun.app/@${
        user.username ? user.username : "nome-de-usuario"
      }`,
      disableArrow: true,
    },
    {
      label: "Descrição",
      value: user.description ? user.description : null,
      helperText: "Adicione",
      path: "settings/update-description",
    },
  ];

  const socialList = [
    {
      label: "Instagram",
      value: user.instagram ? user.instagram : null,
      helperText: "Adicione",
      path: "settings/update-instagram",
    },
    {
      label: "TikTok",
      value: user.tiktok ? user.tiktok : null,
      helperText: "Adicione",
      path: "settings/update-tiktok",
    },
    {
      label: "Twitter",
      value: user.twitter ? user.twitter : null,
      helperText: "Adicione",
      path: "settings/update-twitter",
    },
    {
      label: "Whatsapp",
      value: user.whatsapp ? maskPhone(user.whatsapp) : null,
      helperText: "Adicione",
      path: "settings/update-whatsapp",
    },
  ];

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 3,
        }}
        onClick={() => {
          navigateEffect("/settings/update-photo", "moveToLeftFromRight");
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Avatar alt="Moisés Silvano" url={user && user.photoURL} />
          <Typography
            fontSize={12}
            marginTop={1}
            sx={{
              cursor: "pointer",
            }}
          >
            Alterar foto
          </Typography>
        </Box>
      </Box>

      <EditableMenu title="Sobre você" list={list} />

      <Divider sx={{ marginY: 3 }} />

      <EditableMenu title="Social" list={socialList} />
    </Container>
  );
};

export default UpdateProfile;
