import { replacePhone } from "./string";

export const openWhatsapp = (number) => {
  const numberFormated = replacePhone(number);

  const text =
    "Olá, tudo bem? \n\n " +
    "Encontrei vocês no letsfun.app e gostaria de saber mais informações sobre reservas.";

  const value = `https://wa.me/55${numberFormated}?text=${text}`;

  console.log("value", value);

  window.open(value);
};
