import { Box, Button } from "@mui/material";
import { updateProfile } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import CropImage from "../../components/organisms/CropImage";
import { auth } from "../../config/firebase";
import { AppContext } from "../../providers/app";
import { EffectContext } from "../../providers/effect";
import { findDownloadUrl, uploadFile } from "../../services/file";
import { updateUser } from "../../services/user";
var md5 = require("md5");

const UpdatePhoto = () => {
  const { setPageTitle, user, setUser } = useContext(AppContext);
  useEffect(() => setPageTitle("Editar imagem de perfil"), [setPageTitle]);

  const [isSubmitted, setIsSubmitted] = useState();
  const [image, setImage] = useState(null);
  const { navigateEffect } = useContext(EffectContext);

  const onSubmit = () => {
    setIsSubmitted(true);

    const ramdom = md5(user.uid + new Date().toISOString());
    const path = `profilePhotos/${ramdom}`;

    uploadFile(image, path).then(async (res) => {
      const photoURL = await findDownloadUrl(path);

      Promise.all([
        updateProfile(auth.currentUser, {
          photoURL,
        }),
        updateUser(auth.currentUser.uid, {
          photoURL,
        }),
      ])
        .then((values) => {
          setUser((current) => ({
            ...current,
            photoURL,
          }));
          navigateEffect('/settings/update-profile', "moveToLeftFromRight");
        })
        .finally(() => {
          setIsSubmitted(false);
        });
    });
  };

  return (
    <div>
      <Box sx={{ mt: 2, mb: 2 }}>
        <CropImage setImage={setImage} />

        <Box marginTop={3} />

        <Button
          type="submit"
          fullWidth
          variant="outlined"
          disabled={isSubmitted || !image}
          onClick={onSubmit}
        >
          SALVAR
        </Button>
      </Box>
    </div>
  );
};

export default UpdatePhoto;
