import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import { Badge, Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NOT_AUTH_PAGES } from "../../constants/pages";
import { AppContext } from "../../providers/app";
import { EffectContext } from "../../providers/effect";
import { checkIfIncludes } from "../../utils/array";

export default function Header() {
  const navigate = useNavigate();
  let location = useLocation();

  const { pageTitle } = useContext(AppContext);
  const { navigateEffect } = useContext(EffectContext);
  const { user, isLoading, notificationsCount } = useContext(AppContext);

  const goBack = () => {
    navigateEffect(-1, "moveToRightFromLeft");
  };

  let hasGoBack = false;
  const goBackPathList = ["/settings", "/parties", "/contents"];

  goBackPathList.forEach((item) => {
    hasGoBack = hasGoBack || !!location.pathname.includes(item);
  });

  if (checkIfIncludes(NOT_AUTH_PAGES, location.pathname)) return <></>;

  return (
    <AppBar position="sticky">
      <Toolbar>
        {!hasGoBack ? (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => navigate("/settings")}
          >
            <SettingsIcon />
          </IconButton>
        ) : (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="Voltar"
            sx={{ mr: 2 }}
            onClick={goBack}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, fontSize: 16 }}
        >
          {pageTitle}
        </Typography>

        {!isLoading && (
          <>
            {user ? (
              <IconButton
                size="small"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={() => {
                  navigateEffect("/notifications", "roomToBottom");
                }}
              >
                <Badge badgeContent={notificationsCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            ) : (
              <>
                <Button
                  onClick={() => {
                    navigateEffect("/login", "moveToBottomFromTop");
                  }}
                >
                  Conecte-se
                </Button>
              </>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
