import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCWXe71JXpggiNT87EHMPhDSNZSvv7AoWs",
  authDomain: "letsfun-88e7b.firebaseapp.com",
  projectId: "letsfun-88e7b",
  storageBucket: "letsfun-88e7b.appspot.com",
  messagingSenderId: "1065431896418",
  appId: "1:1065431896418:web:2b38619b180e3b18d19c3b",
  measurementId: "G-80013841RK",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };
