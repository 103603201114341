import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router";
import AppTemplate from "../components/templates/AppTemplate";
import { AppContext } from "../providers/app";
import { EffectContext } from "../providers/effect";
import { getUsers } from "../services/user";

const Meet = () => {
  const { setPageTitle } = useContext(AppContext);
  const { navigateEffect } = useContext(EffectContext);

  const [list, setList] = useState([]);

  useEffect(() => setPageTitle("Usuários ao redor"), [setPageTitle]);

  useEffect(() => {
    getUsers().then((res) => {
      res && setList(res);
    });
  }, []);

  return (
    <Box>
      <AppTemplate>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {list.map((item, index) => (
            <Box
              key={index}
              onClick={() => {
                navigateEffect(
                  item
                    ? `${item.username ? `@${item.username}` : `u/${item._id}`}`
                    : "no-account"
                );
              }}
            >
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt={item.displayName} src={item.photoURL} />
                </ListItemAvatar>
                <ListItemText primary={item.displayName} />
              </ListItem>
              <Divider variant="inset" component="li" />
            </Box>
          ))}
        </List>
      </AppTemplate>
    </Box>
  );
};

export default Meet;
