import {
  Button,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import * as yup from "yup";
import { REQUIRED_FIELD } from "../../constants/error";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
const schema = yup
  .object({
    instagram: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(/^(\S+$)/g, "* This field cannot contain only blankspaces"),
    name: yup.string().required(REQUIRED_FIELD),
    description: yup.string(),
  })
  .required();

const FormPartyContact = ({ initialValues, handleNext, handleBack }) => {
  const [list, setList] = useState([]);

  const [phone, setPhone] = useState();

  const add = () => {
    if (!phone) return;
    if (phone.length < 15) return;

    setPhone(null);

    setList((current) => [
      ...current,
      {
        value: phone
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .replace("-", ""),
        type: "whatsapp",
      },
    ]);
  };

  const remove = (pos) => {
    const newList = list.filter((item, index) => index != pos);
    setList(newList);
  };

  const onSubmit = () => {
    handleNext({
      contacts: list,
    });
  };

  useEffect(() => {
    if (initialValues && initialValues.contacts) {
      setList(initialValues.contacts);
    }
  }, [initialValues]);

  return (
    <Box
      sx={{
        marginY: 2,
      }}
    >
      <Box
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputMask
          mask="(99) 99999-9999"
          value={phone}
          disabled={false}
          maskChar=" "
          onChange={(e) => setPhone(e.target.value)}
        >
          {() => <TextField label="Whatsapp" fullWidth />}
        </InputMask>
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          variant="outlined"
          onClick={add}
        >
          <AddIcon />
        </IconButton>
      </Box>

      <TableContainer
        sx={{
          marginTop: 2,
        }}
      >
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Valor</TableCell>
              <TableCell align="right">Tipo</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row, index) => (
              <TableRow key={row.value}>
                <TableCell component="th" scope="row">
                  <Link
                    href={`https://wa.me/${row.value}`}
                    target="_blank"
                    sx={{
                      textDecoration: "none",
                    }}
                  >
                    {row.value}
                  </Link>
                </TableCell>
                <TableCell align="right">{row.type}</TableCell>
                <TableCell align="right">
                  <IconButton size="small" onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box marginTop={2}>
        <Button
          variant="outlined"
          sx={{ mt: 1, mr: 1 }}
          disabled={list.length === 0}
          onClick={onSubmit}
        >
          CONTINUAR
        </Button>
        <Button
          type="button"
          variant="text"
          sx={{ mt: 1, mr: 1 }}
          onClick={handleBack}
        >
          VOLTAR
        </Button>
      </Box>
    </Box>
  );
};

export default FormPartyContact;
