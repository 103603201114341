import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/firebase";
import { getUserById } from "../services/user";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [userFB, setUserFB] = useState();
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [pageTitle, setPageTitle] = useState("LetsFun");

  const notificationsCount = 0;

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserFB({ ...user });
      }
    });
  }, []);

  useEffect(() => {
    if (userFB) {
      getUserById(userFB.uid).then((res) => {
        if (res) {
          setUser({
            ...userFB,
            ...res,
          });

          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
    }
  }, [userFB]);

  return (
    <AppContext.Provider
      value={{
        isLoading,
        notificationsCount,
        user,
        setUser,
        pageTitle,
        setPageTitle,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
