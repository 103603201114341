import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { REQUIRED_FIELD } from "../../constants/error";
import { getAddress, getGeolocation } from "../../services/address";
import Input from "../molecules/Input";
import InputMask from "react-input-mask";
import { geohashGenerator } from "../../utils/geo";
import { GeoPoint } from "firebase/firestore";

const schema = yup
  .object({
    zipcode: yup.string().min(8).max(8).required(REQUIRED_FIELD),
    street: yup.string().required(REQUIRED_FIELD),
    number: yup.string().required(REQUIRED_FIELD),
    complement: yup.string(),
    district: yup.string().required(REQUIRED_FIELD),
    city: yup.string().required(REQUIRED_FIELD),
    state: yup.string().required(REQUIRED_FIELD),
    country: yup.string().required(REQUIRED_FIELD),
  })
  .required();

const FormPartyAddress = ({ initialValues, handleNext, handleBack }) => {
  const { address } = initialValues;

  const [isLoading, setIsLoading] = useState(false);

  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [geohash, setGeohash] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const numberRef = useRef(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      zipcode: address ? address.zipcode || "" : "",
      street: address ? address.street || "" : "",
      number: address ? address.number || "" : "",
      complement: address ? address.complement || "" : "",
      district: address ? address.district || "" : "",
      city: address ? address.city || "" : "",
      state: address ? address.state || "" : "",
      country: address ? address.country || "" : "",
    },
  });

  const findAddressByCep = (e) => {
    const zipcode = e.target.value;
    setValue("zipcode", zipcode);

    if (zipcode.length < 8) return;

    setIsLoading(true);

    getAddress(zipcode, "286d79b198aebc505591ec91ecc59b91")
      .then((res) => {
        setValue("street", res.street);
        setValue("complement", res.complement);
        setValue("city", res.city);
        setValue("district", res.district);
        setValue("state", res.state);
        setValue("country", res.country);
        setLat(null);
        setLong(null);

        numberRef.current.focus();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    setFormValues(data);

    getGeolocation(data, "286d79b198aebc505591ec91ecc59b91")
      .then((res) => {
        setLat(res.lat);
        setLong(res.long);

        setGeohash(geohashGenerator(res.lat, res.long));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitGeo = () => {
    handleNext({
      address: {
        ...formValues,
        pos: new GeoPoint(lat, long),
        geohash,
      },
    });
  };

  const reset = () => {
    setValue("zipcode", "");
    setValue("number", "");
    setValue("street", "");
    setValue("number", "");
    setValue("complement", "");
    setValue("city", "");
    setValue("district", "");
    setValue("state", "");
    setValue("country", "");
    setLong("");
    setLat("");
  };

  useEffect(() => {
    if (address && address.pos) {
      setLat(address.pos._lat);
      setLong(address.pos._long);
      setGeohash(address.geohash);
    }
  }, [address]);

  return (
    <Box
      component="form"
      noValidate
      sx={{ marginY: 2 }}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Input
            type="text"
            name="zipcode"
            control={control}
            label="CEP"
            errors={errors.zipcode}
            onChange={findAddressByCep}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {isLoading && <CircularProgress size="1.5rem" />}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            type="text"
            name="street"
            control={control}
            label="Endereço"
            errors={errors.street}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="text"
            name="number"
            control={control}
            label="Número"
            errors={errors.number}
            inputRef={numberRef}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="text"
            name="complement"
            control={control}
            label="Complemento"
            errors={errors.complement}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="text"
            name="district"
            control={control}
            label="Bairro"
            errors={errors.district}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="text"
            name="city"
            control={control}
            label="Cidade"
            errors={errors.city}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="text"
            name="state"
            control={control}
            label="Estado"
            errors={errors.state}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="text"
            name="country"
            control={control}
            label="País"
            errors={errors.country}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="text"
            sx={{ mt: 1, mr: 1 }}
            disabled={isLoading || (lat && long)}
          >
            CONFIRMAR ENDEREÇO
          </Button>
        </Grid>
      </Grid>

      {lat && long && (
        <>
          <Grid
            container
            spacing={1}
            sx={{
              mt: 2,
            }}
          >
            <Grid item xs={6}>
              <TextField type="text" name="lat" label="Latitude" value={lat} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                name="long"
                label="Longitude"
                value={long}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                name="geohash"
                label="Geohash"
                value={geohash}
              />
            </Grid>
          </Grid>

          <Box marginTop={2}>
            <Button
              type="button"
              variant="outlined"
              sx={{ mt: 1, mr: 1 }}
              onClick={onSubmitGeo}
            >
              CONTINUAR
            </Button>
            <Button
              type="button"
              variant="text"
              sx={{ mt: 1, mr: 1 }}
              onClick={handleBack}
            >
              VOLTAR
            </Button>
            <Button
              type="button"
              variant="text"
              sx={{ mt: 1, mr: 1 }}
              onClick={reset}
            >
              RESETAR
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default FormPartyAddress;
