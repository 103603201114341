import { Alert, Box, Button, LinearProgress } from "@mui/material";
import React, { useContext } from "react";
import { EffectContext } from "../../providers/effect";

const items = [
  {
    text: "Adicione uma imagem de perfil",
    btn: "ALTERAR",
    path: "settings/update-photo",
    param: "user.photoURL",
  },
  {
    text: "Adicione um nome de usuário",
    path: "settings/update-username",
    btn: "ALTERAR",
    param: "user.username",
  },
  {
    text: "Adicione uma foto em seu feed",
    path: "settings/user-photos",
    btn: "ADICIONAR",
    param: "contents",
    type: "size",
  },
  {
    text: "Adicione uma descrição",
    path: "settings/update-description",
    btn: "ADICIONAR",
    param: "user.description",
  },
  {
    text: "Adicione seu Instagram",
    path: "settings/update-instagram",
    btn: "ADICIONAR",
    param: "user.instagram",
  },
];

const ProfileProgress = ({ params }) => {
  let selected = null;
  let percentage = 0;
  let percentageCount = 100 / items.length;

  items.forEach((item, index) => {
    const split = item.param.split(".");

    if (selected) return;

    percentage += percentageCount;

    if (split.length === 1) {
      if (item.type === "size") {
        if (params[split[0]].length === 0) {
          selected = item;
        }
      } else {
        if (!params[split[0]]) {
          selected = item;
        }
      }
    }

    if (split.length === 2) {
      if (item.type === "size") {
        if (params[split[0]][split[1]] === 0) {
          selected = item;
        }
      } else {
        if (!params[split[0]][split[1]]) {
          selected = item;
        }
      }
    }
  });

  const { navigateEffect } = useContext(EffectContext);

  if (!selected) return <></>;

  return (
    <Box>
      <LinearProgress variant="determinate" value={percentage} />
      <Alert
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => navigateEffect(selected.path)}
          >
            {selected.btn}
          </Button>
        }
        color="primary"
        severity="info"
      >
        {selected.text}
      </Alert>
    </Box>
  );
};

export default ProfileProgress;
