import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { getGeohashRange } from "../utils/geo";
import { formatPlaceData } from "../utils/place";
const geofire = require("geofire-common");

const COLLECTION = "places";
const placesRef = collection(db, COLLECTION);

export const getPlacesByLocation = async (latitude, longitude, distance) => {
  const location = [latitude, longitude];
  const range = getGeohashRange(latitude, longitude, distance);

  const q = query(
    placesRef,
    where("address.geohash", ">=", range.lower),
    where("address.geohash", "<=", range.upper),
    orderBy("address.geohash")
  );

  const documentSnapshots = await getDocs(q);

  const result = documentSnapshots.docs.map((docSnap) => {
    const lat = docSnap.get("address.pos")._lat;
    const lng = docSnap.get("address.pos")._long;

    const distanceInKm = geofire.distanceBetween([lat, lng], location);
    const distanceInM = distanceInKm * 1000;

    return {
      _id: docSnap.id,
      ...docSnap.data(),
      distanceInKm: parseInt(distanceInKm),
      distanceInM: parseInt(distanceInM),
    };
  });

  return result.sort((a, b) => a.distanceInKm - b.distanceInKm);
};

export const getPlaceByInstagram = async (instagram) => {
  const q = query(placesRef, where("instagram", "==", instagram));

  const documentSnapshots = await getDocs(q);

  const result = documentSnapshots.docs.map((docSnap) => {
    const lat = docSnap.get("address.pos")._lat;
    const lng = docSnap.get("address.pos")._long;


    return {
      _id: docSnap.id,
      ...docSnap.data(),
      // distanceInKm: parseInt(distanceInKm),
      // distanceInM: parseInt(distanceInM),
      lat,
      lng,
    };
  });

  return result.sort((a, b) => a.distanceInKm - b.distanceInKm);
};

export const getPlacesByFilter = async (name) => {
  const q = query(
    placesRef,
    orderBy("name"),
    where("name", ">=", name),
    where("name", "<=", name + "\uf8ff")
  );

  const documentSnapshots = await getDocs(q);

  return documentSnapshots.docs.map((docSnap) => ({
    _id: docSnap.id,
    ...docSnap.data(),
  }));
};

export const getPlaceById = async (id) => {
  const docRef = doc(db, COLLECTION, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists())
    return {
      _id: docSnap.id,
      ...docSnap.data(),
    };
  else return null;
};

export const updatePlace = async (id, data) => {
  const docRef = doc(db, COLLECTION, id);

  return setDoc(docRef, formatPlaceData(data), {
    merge: true,
  });
};

export const createPlace = async (data) => {
  return addDoc(placesRef, formatPlaceData(data));
};
