import { yupResolver } from "@hookform/resolvers/yup";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { FormHelperText, Link } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../components/atoms/Button";
import Copyright from "../components/atoms/Copyright";
import Input from "../components/molecules/Input";
import InputPassword from "../components/molecules/InputPassword";
import { auth } from "../config/firebase";
import { EffectContext } from "../providers/effect";
import { blockSpaces } from "../utils/string";
import { createUser, updateUser } from "../services/user";
import { serverTimestamp } from "firebase/firestore";
const schema = yup
  .object({
    email: yup.string().email().required("Digite seu e-mail"),
    password: yup.string().required("Digite sua senha"),
  })
  .required();

const Login = () => {
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { navigateEffect } = useContext(EffectContext);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);

    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((res) => {
        navigateEffect("/", "moveToTopFromBottom");
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        updateUser(user.uid, {
          displayName: user.displayName,
          photoURL: user.photoURL,
          createAt: serverTimestamp(),
        });

        window.location.href = "/";

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        navigateEffect("/", "moveToTopFromBottom");

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Container maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Entrar
          </Typography>

          <FormHelperText error={!!error}>{error}</FormHelperText>

          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Input
              type="email"
              name="email"
              control={control}
              label="E-mail"
              autoComplete="email"
              errors={errors.email}
              margin="normal"
              onChange={({ target }) => {
                setValue("email", blockSpaces(target.value));
              }}
            />
            <InputPassword
              name="password"
              control={control}
              label="Senha"
              errors={errors.password}
              margin="normal"
            />

            <Grid
              item
              sx={{
                textAlign: "right",
                width: "100%",
              }}
            >
              <Link
                onClick={() => {
                  navigateEffect("/recover-password", "carouselToLeft");
                }}
                sx={{
                  textDecoration: "none",
                  fontSize: 14,
                }}
                component="button"
              >
                Esqueceu a senha?
              </Link>
            </Grid>

            <Box sx={{ mt: 3, mb: 3 }}>
              <Button type="submit" fullWidth disabled={isSubmitted}>
                Entrar
              </Button>
            </Box>
            <Box sx={{ mt: 3, mb: 3 }}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography fontSize={12}>ou entrar com</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="button"
                    variant="outlined"
                    fullWidth
                    startIcon={<GoogleIcon />}
                    size="small"
                    onClick={signInWithGoogle}
                  >
                    GOOGLE
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="button"
                    variant="outlined"
                    fullWidth
                    startIcon={<FacebookIcon />}
                    size="small"
                    onClick={signInWithFacebook}
                  >
                    FACEBOOK
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Grid container>
              <Grid
                item
                sx={{
                  textAlign: "center",
                  width: "100%",
                  marginTop: 2,
                }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    navigateEffect("/register", "carouselToLeft");
                  }}
                >
                  Não tem uma conta? Cadastre-se
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </Box>
  );
};

export default Login;
