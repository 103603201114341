import {
  Button
} from "@mui/material";
import { Box } from "@mui/system";
import * as yup from "yup";
import { REQUIRED_FIELD } from "../../constants/error";
import Place from "./Place";

const schema = yup
  .object({
    instagram: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(/^(\S+$)/g, "* This field cannot contain only blankspaces"),
    name: yup.string().required(REQUIRED_FIELD),
    description: yup.string(),
  })
  .required();

const FormPartyPreview = ({ initialValues, handleSubmit, handleBack }) => {
  return (
    <Box
      sx={{
        marginY: 2,
      }}
    >
      <Place place={initialValues} isPreview={true} />

      <Box marginTop={2}>
        <Button variant="outlined" sx={{ mt: 1, mr: 1 }} onClick={handleSubmit}>
          CONCLUIR
        </Button>
        <Button
          type="button"
          variant="text"
          sx={{ mt: 1, mr: 1 }}
          onClick={handleBack}
        >
          VOLTAR
        </Button>
      </Box>
    </Box>
  );
};

export default FormPartyPreview;
