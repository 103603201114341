import { Outlet } from "react-router-dom";
import AppTemplate from "../../components/templates/AppTemplate";

const Party = () => {
  return (
    <AppTemplate>
      <Outlet />
    </AppTemplate>
  );
};

export default Party;
