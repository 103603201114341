import { Box, Button } from "@mui/material";
import { useContext, useEffect } from "react";
import Input from "../../components/molecules/Input";
import { AppContext } from "../../providers/app";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputPassword from "../../components/molecules/InputPassword";
import { reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import { EffectContext } from "../../providers/effect";

const schema = yup
  .object({
    currentPassword: yup.string().required("Digite a senha atual"),
    password: yup.string().required("Digite a nova senha"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Senhas não conferem")
      .required("Digite a confirmação da senha"),
  })
  .required();

const UpdatePassword = () => {
  const { user, setPageTitle } = useContext(AppContext);
  useEffect(() => setPageTitle("Alterar senha"), [setPageTitle]);
  const { navigateEffect } = useContext(EffectContext);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    reauthenticateWithCredential(auth.currentUser, {
      email: user.email,
      password: data.currentPassword,
    })
      .then((res) => {
        updatePassword(res.user, data.password)
          .then((resChild) => {
            navigateEffect("/settings", "moveToLeftFromRight");
          })
          .catch((errChild) => {
          });
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputPassword
          name="currentPassword"
          control={control}
          label="Senha atual"
          errors={errors.currentPassword}
          margin="normal"
          autoFocus
        />
        <InputPassword
          name="password"
          control={control}
          label="Nova senha"
          errors={errors.password}
          margin="normal"
        />
        <InputPassword
          name="confirmPassword"
          control={control}
          label="Confirmar senha"
          errors={errors.confirmPassword}
          margin="normal"
        />

        <Box sx={{ mt: 2, mb: 2 }}>
          <Button type="submit" fullWidth variant="outlined">
            SALVAR
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default UpdatePassword;
