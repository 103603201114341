import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

const LocationInfo = () => {
  return (
    <Paper
      sx={{
        padding: 2,
        marginTop: 2,
      }}
    >
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <img
          width="120px"
          src="https://pngroyale.com/wp-content/uploads/2021/12/Download-free-map-gps-location-icon-navigation-software-PNG.png"
        />
        <Typography fontWeight={700}>Aonde você está no mundo?</Typography>
        <Typography fontSize={12} marginY={1}>
          Para melhor experiencia no LetsFun o serviço de localização deve ser
          ativado
        </Typography>
        <Typography fontSize={12}>
          Nunca compartilhamos a sua localização exata com ninguém!
        </Typography>
      </Box>
    </Paper>
  );
};

export default LocationInfo;
