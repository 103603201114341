import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Box, Chip, ListItemIcon, Typography } from "@mui/material";
import { useContext } from "react";
import { EffectContext } from "../../providers/effect";
import { showDays } from "../../utils/days";
import { openWhatsapp } from "../../utils/whatsapp";
import Button from "../atoms/Button";
import Story from "../atoms/Story";

const Place = ({ place, isPreview }) => {
  const { navigateEffect } = useContext(EffectContext);

  const goToPlace = () => {
    navigateEffect("/" + place.instagram, "moveToBottomFromTop");
  };

  return (
    <Box
      sx={{
        display: "flex",
        marginY: "20px",
        borderRadius: "4px",
        paddingY: "5px",
      }}
    >
      <Box
        sx={{
          width: "45px",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {place.contents && place.contents.length > 0 ? (
          <Story image={place.contents[0]} />
        ) : (
          <Story image="https://lets.events/blog/wp-content/uploads/2017/09/top-da-balada-o-que-faz-uma-balada-ter-sucesso.jpeg" />
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          padding: "5px",
          marginLeft: "5px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={goToPlace}
        >
          <Typography
            sx={{
              fontWeight: "600",
              flex: 1,
            }}
          >
            {place.name}
          </Typography>

          {!isPreview && (
            <Box>
              <FavoriteBorderIcon
                sx={{
                  width: "20px",
                }}
              />
            </Box>
          )}
        </Box>

        <Typography
          sx={{
            fontSize: 10,
          }}
          onClick={goToPlace}
        >
          {place.operations && showDays(place.operations.map((op) => op.day))}
          <Box
            component="span"
            sx={{
              marginX: 1,
              paddingX: 1,
              border: "1px solid #fff",
              borderRadius: 1,
            }}
          >
            22h às 06h
          </Box>
        </Typography>

        {!isPreview && (
          <Box
            sx={{ flex: 1, fontSize: 10, marginTop: "5px" }}
            onClick={goToPlace}
          >
            {place.distanceInKm}km de distância
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "-10px",
            flex: isPreview ? 1 : "",
          }}
          onClick={goToPlace}
        >
          <Box>
            {place.musicalStyles &&
              place.musicalStyles.map((item) => (
                <Chip
                  label={item.value}
                  variant="outlined"
                  size="small"
                  sx={{ fontSize: 10 }}
                  key={item.key}
                />
              ))}
          </Box>
          {!isPreview && (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                variant="text"
                onClick={goToPlace}
                size="small"
              >
                VER MAIS
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Place;
