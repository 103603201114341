import { Box } from "@mui/material";

const Image = ({ url }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "120px",
        backgroundImage: `url(${url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: 1,
        marginRight: "5px",
        border: "2px solid #ba68c",
      }}
    ></Box>
  );
};

export default Image;
