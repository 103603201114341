import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usePosition } from "use-position";
import GridProfile from "../../components/molecules/GridProfile";
import { getPlaceByInstagram } from "../../services/places";
import { showDay, showDays } from "../../utils/days";
import { gMapsLinkAddress } from "../../utils/maps";
import { openWhatsapp } from "../../utils/whatsapp";
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
const geofire = require("geofire-common");

const View = () => {
  let { username, id } = useParams();

  const [place, setPlace] = useState();

  const { latitude, longitude } = usePosition();

  useEffect(() => {
    if (username) {
      getPlaceByInstagram(username, latitude, longitude).then((res) => {
        if (res && res.length > 0) {
          setPlace(res[0]);
        }
      });
    }
  }, [username]);

  if (!place) return <></>;

  const distanceInKm = parseInt(
    geofire.distanceBetween([place.lat, place.lng], [latitude, longitude])
  );

  let initialTime,
    finalTime,
    isDifferent = false;

  place.operations.map((op, index) => {
    initialTime = op.initialTime;
    finalTime = op.finalTime;

    if (index > 0) {
      isDifferent =
        initialTime !== op.initialTime || finalTime !== op.finalTime;
    }
  });

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          marginTop: 2,
        }}
      >
        <Box
          sx={{
            marginY: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              alt={place.displayName}
              src={
                place.photoURL
                  ? place.photoURL
                  : "https://assets.dsop.com.br/wp-content/uploads/2021/08/balada.jpg"
              }
              sx={{
                width: "100px",
                height: "100px",
              }}
            />
          </Box>
          <Box
            sx={{
              marginTop: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
              }}
            >
              {place.name}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ marginTop: 1 }}>
          {place.musicalStyles &&
            place.musicalStyles.map((item) => (
              <Chip
                label={item.value}
                variant="outlined"
                size="small"
                sx={{ fontSize: 10, marginX: 0.5 }}
                key={item.key}
              />
            ))}
        </Box>

        {!isDifferent ? (
          <Box sx={{ marginTop: 2 }}>
            <Typography
              sx={{
                fontSize: 10,
              }}
            >
              {place.operations &&
                showDays(place.operations.map((op) => op.day))}
              <Box
                component="span"
                sx={{
                  marginX: 1,
                  paddingX: 1,
                  border: "1px solid #fff",
                  borderRadius: 1,
                }}
              >
                {initialTime}h às {finalTime}h
              </Box>
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              borderRadius: "4px",
              margin: "15px auto",
            }}
          >
            {place.operations.map((op) => (
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 10,
                  }}
                >
                  {showDay([op.day])}
                </Typography>
                <Box
                  component="span"
                  sx={{
                    marginX: 1,
                    paddingX: 1,
                    borderRadius: 1,
                    fontSize: 10,
                  }}
                >
                  22h às 06h
                </Box>
              </Box>
            ))}

            {/* <Typography
              sx={{
                fontSize: 12,
              }}
            >
              {place.operations &&
                showDays(place.operations.map((op) => op.day))}
              <Box
                component="span"
                sx={{
                  marginX: 1,
                  paddingX: 1,
                  border: "1px solid #fff",
                  borderRadius: 1,
                }}
              >
                22h às 06h
              </Box>
            </Typography> */}
          </Box>
        )}

        <Box sx={{ marginTop: 2, fontSize: 10 }}>
          {distanceInKm}km de distância
        </Box>

        {place.description && (
          <Box sx={{ marginTop: 1 }}>{place.description} </Box>
        )}
      </Box>

      <Container maxWidth="sm">
        <Grid container spacing={1} marginTop={2} marginBottom={2}>
          <Grid item xs={12}>
            <Button
              onClick={() => openWhatsapp(place.contacts[0].value)}
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#363636",
              }}
              size="small"
              endIcon={<LocalFireDepartmentIcon />}
            >
              QUERO RESERVAR 
            </Button>
          </Grid>
        </Grid>

        {place.contents && place.contents.length > 0 && (
          <Box
            sx={{
              marginY: 3,
            }}
          >
            <GridProfile
              displayName={place.name}
              images={place.contents}
              isAuthUser={false}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                size="small"
                onClick={() =>
                  window.open("https://instagram.com/" + place.instagram)
                }
              >
                VER MAIS NO INSTAGRAM
              </Button>
            </Box>
          </Box>
        )}

        {/* <Box sx={{ marginTop: 4, textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: 10,
            }}
          >
            {place.address.zipcode} - {place.address.street},{" "}
            {place.address.number} {place.address.complement}
          </Typography>
          <Typography
            sx={{
              fontSize: 10,
            }}
          >
            {place.address.district} - {place.address.city} -{" "}
            {place.address.state}
          </Typography>
        </Box> */}

        <Box
          sx={{
            width: "100%",
            height: "80px",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 3,
            cursor: "pointer",
          }}
          onClick={() => window.open(gMapsLinkAddress(place.address))}
        >
          <Box
            sx={{
              backgroundImage:
                "url(https://classic.exame.com/wp-content/uploads/2020/02/20190301101226_1200_675_-_google_maps-1.jpg?quality=70&strip=info&w=682)",
              width: "100%",
              height: "80px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: 0.2,
              borderRadius: "4px",
              position: "absolute",
            }}
          />
          <Button
            variant="text"
            fullWidth
            sx={{
              width: "120px",
              height: "40px",
              color: "#fff",
            }}
            size="small"
            onClick={() => window.open(gMapsLinkAddress(place.address))}
          >
            ABRIR NO MAPA
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default View;
