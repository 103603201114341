import { Link as RouterLink } from "react-router-dom";
import LinkMui from "@mui/material/Link";

const Link = ({ children, fontSize, ...props }) => {
  return (
    <LinkMui
      component={RouterLink}
      {...props}
      sx={{
        textDecoration: "none",
        fontSize: fontSize
      }}
    >
      {children}
    </LinkMui>
  );
};

export default Link;
