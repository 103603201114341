import { Box } from "@mui/material";

const Contents = () => {
  return (
    <Box>
      <Box>
asdasd
      </Box>
    </Box>
  );
};

export default Contents;
