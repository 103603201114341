import { Box } from "@mui/material";
import { Container } from "@mui/system";

const AppTemplate = ({ children }) => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 110px)",
        overflowY: "scroll",
      }}
    >
      <Container
        component="main"
        maxWidth="md"
        sx={{
          paddingRight: 0,
          paddingLeft: 0,
          marginBottom: 6,
        }}
      >
        {children}
      </Container>
    </Box>
  );
};
export default AppTemplate;
