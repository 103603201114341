import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/firebase";
import { routesFooter } from "../components/molecules/Footer";
import { useLocation, useNavigate } from "react-router-dom";

export const EffectContext = createContext();

const EffectProvider = ({ children }) => {
  let location = useLocation();
  let navigate = useNavigate();

  const [preset, setPreset] = useState("");
  const [pathNavigate, setPathNavigate] = useState(null);

  const navigateEffect = (path, effect) => {
    setPreset(effect);
    setPathNavigate(path);
  };

  useEffect(() => {
    if (pathNavigate) {
      navigate(pathNavigate);
      setPathNavigate(null);
    }
  }, [pathNavigate, navigate]);

  return (
    <EffectContext.Provider
      value={{
        preset,
        setPreset,
        navigateEffect,
      }}
    >
      {children}
    </EffectContext.Provider>
  );
};

export default EffectProvider;
