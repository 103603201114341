import { Box, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useDebounceEffect } from "../../hooks/useDebounceEffect";
import { canvasPreview, getCroppedImg } from "../../utils/canvas";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const CropImage = ({ setImage }) => {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const inputRef = useRef();

  const scale = 1;
  const rotate = 0;
  const aspect = 8 / 8;

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const handleOpenFileInput = () => {
    inputRef.current.click();
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        getCroppedImg(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        ).then((res) => {
          res && setImage(res);
        });
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <div className="App">
      <Box
        sx={{
          display: "none",
        }}
      >
        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />
      </Box>

      {!imgSrc && (
        <Box>
          <Box
            sx={{
              width: "100%",
              height: "120px",
              borderRadius: 1,
              marginRight: "5px",
              border: "2px solid #363636",
              color: "#808080",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              cursor: "pointer",
              fontSize: 14,
            }}
            onClick={handleOpenFileInput}
          >
            SELECIONAR IMAGEM
          </Box>
        </Box>
      )}

      {!!imgSrc && (
        <Box
          sx={{
            marginBottom: 2,
          }}
        >
          <Button
            type="button"
            fullWidth
            variant="text"
            onClick={handleOpenFileInput}
          >
            SELECIONAR OUTRA IMAGEM
          </Button>
        </Box>
      )}

      {!!imgSrc && (
        <Box
          sx={{
            width: "80%",
            margin: "0 auto",
          }}
        >
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </Box>
      )}
      <Box
        sx={{
          display: "none",
        }}
      >
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: "1px solid black",
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </Box>
    </div>
  );
};

export default CropImage;
