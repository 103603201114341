import { Alert, Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useContext, useState } from "react";
import Slider from "react-slick";
import { auth } from "../../config/firebase";
import { AppContext } from "../../providers/app";
import { EffectContext } from "../../providers/effect";
import { updateUser } from "../../services/user";
import AddImage from "../atoms/AddImage";
import Image from "../atoms/Image";
import BootstrapDialogTitle from "./BootstrapDialogTitle";

const GridProfile = ({ displayName, images, isAuthUser, setProfileUser }) => {
  const { setUser } = useContext(AppContext);

  const { navigateEffect } = useContext(EffectContext);

  const [selected, setSelected] = useState();
  const [current, setCurrent] = useState();
  const [infoIsOpen, setInfoIsOpen] = useState(!localStorage.getItem("drop"));

  const handleClose = () => setSelected(null);

  const confirmDrop = () => {
    localStorage.setItem("drop", true);
    setInfoIsOpen(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: selected - 1 || 0,
    beforeChange: (current, next) => {
      setCurrent(next);
    },
  };

  const deleteImage = (pos) => {
    const contents = images.filter((img, index) => index !== pos);

    updateUser(auth.currentUser.uid, {
      contents,
    }).then(() => {
      setUser((current) => ({
        ...current,
        contents,
      }));
      setProfileUser((current) => ({
        ...current,
        contents,
      }));

      setSelected(null);
    });
  };

  return (
    <>
      <Grid container spacing={1}>
        {images.map((item, index) => (
          <Grid
            item
            xs={4}
            key={index}
            onClick={() => setSelected(index + 1)}
            sx={{
              cursor: "pointer",
            }}
          >
            <Image url={item} />
          </Grid>
        ))}
        {Array.from({ length: isAuthUser ? 6 - images.length : 0 }).map(
          (item, index) => (
            <Grid
              item
              xs={4}
              key={index}
              onClick={() => {
                navigateEffect("/settings/user-photos", "moveToRightFromLeft");
              }}
              sx={{
                cursor: "pointer",
              }}
            >
              <AddImage />
            </Grid>
          )
        )}
      </Grid>

      {images.length === 0 && !isAuthUser && (
        <Box
          sx={{
            border: "2px solid #363636",
            width: "100%",
            textAlign: "center",
            borderRadius: "4px",
            marginTop: 5,
            paddingY: 3,
          }}
        >
          <Typography
            sx={{
              color: "#808080",
            }}
          >
            Ainda não há imagens por aqui
          </Typography>
        </Box>
      )}

      <Dialog
        open={!!selected}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            backgroundColor: "#000",
            fontSize: 14,
          }}
        >
          {displayName}
        </BootstrapDialogTitle>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#000",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "50%",
              },
            }}
          >
            <Slider {...settings}>
              {images.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    height: "100%",
                  }}
                >
                  <img src={item} alt="Imagem" width="100%" />
                </Box>
              ))}
            </Slider>

            {infoIsOpen && (
              <Box>
                <Alert
                  severity="info"
                  action={
                    <Button color="inherit" size="small" onClick={confirmDrop}>
                      OK
                    </Button>
                  }
                >
                  Arraste para o lado para visualizar outras imagens
                </Alert>
              </Box>
            )}

            {isAuthUser && (
              <Box
                sx={{
                  position: "fixed",
                  bottom: 0,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Button variant="text" onClick={() => deleteImage(current)}>
                  Deletar
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default GridProfile;
