import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, InputAdornment } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Input from "../../components/molecules/Input";
import { auth } from "../../config/firebase";
import { REQUIRED_FIELD } from "../../constants/error";
import { AppContext } from "../../providers/app";
import { updateUser } from "../../services/user";
import {
  blockDotsOnStart,
  blockSpecialCaracter,
  maskPhone,
  replacePhone,
} from "../../utils/string";
import whatsappIcon from "../../assets/images/whatsapp.png";
import { EffectContext } from "../../providers/effect";

const schema = yup
  .object({
    phone: yup.string().required(REQUIRED_FIELD),
  })
  .required();

const UpdateWhatsapp = () => {
  const { setPageTitle, user, setUser } = useContext(AppContext);
  useEffect(() => setPageTitle("Editar Whastapp"), [setPageTitle]);

  const [isSubmitted, setIsSubmitted] = useState();
  const { navigateEffect } = useContext(EffectContext);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);

    updateUser(auth.currentUser.uid, {
      whatsapp: data.phone,
    })
      .then((values) => {
        setUser((current) => ({
          ...current,
          whatsapp: replacePhone(data.phone),
        }));
        navigateEffect("/settings/update-profile", "moveToLeftFromRight");
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  useEffect(() => {
    user && setValue("phone", user.whatsapp);
  }, [user]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginY: 2,
          opacity: 0.7,
        }}
      >
        <img width={100} src={whatsappIcon} alt="Whastapp" />
      </Box>

      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Input
          type="text"
          name="phone"
          control={control}
          label="Número do Whatsapp"
          errors={errors.phone}
          margin="normal"
          onChange={({ target }) => {
            setValue("phone", maskPhone(target.value));
          }}
        />

        <Box sx={{ mt: 2, mb: 2 }}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            disabled={isSubmitted}
          >
            SALVAR
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default UpdateWhatsapp;
