import { Box, Divider, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Loading from "../components/molecules/Loading";
import SelectLocation from "../components/molecules/SelectLocation";
import Place from "../components/organisms/Place";
import AppTemplate from "../components/templates/AppTemplate";
import { AppContext } from "../providers/app";
import { getPlacesByLocation } from "../services/places";

const Home = () => {
  const { user, setPageTitle } = useContext(AppContext);

  const [places, setPlaces] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getPlaces = () => {
    setIsLoading(true);
    const distance = 10;

    getPlacesByLocation(userLocation.lat, userLocation.long, distance)
      .then((res) => {
        setPlaces(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (userLocation) {
      setPlaces([]);
      getPlaces();
    }
  }, [userLocation]);

  useEffect(() => setPageTitle("LetsFun"), [setPageTitle]);

  return (
    <Box>
      <AppTemplate>
        <SelectLocation
          setUserLocation={setUserLocation}
          userLocation={userLocation}
        />

        <Box
          sx={{
            marginX: "15px",
            borderRadius: "8px",
            marginBottom: 10,
            marginTop: 2,
          }}
        >
          <Grid container spacing={3}>
            {places.map((place, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Place place={place} />
                <Divider />
              </Grid>
            ))}
          </Grid>
        </Box>
      </AppTemplate>
    </Box>
  );
};

export default Home;
