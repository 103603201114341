const geofire = require("geofire-common");

export const getGeohashRange = (latitude, longitude, distance) => {
  const lat = 0.0144927536231884;
  const lon = 0.0181818181818182;

  const lowerLat = latitude - lat * distance;
  const lowerLon = longitude - lon * distance;

  const upperLat = latitude + lat * distance;
  const upperLon = longitude + lon * distance;

  const lower = geofire.geohashForLocation([lowerLat, lowerLon]);
  const upper = geofire.geohashForLocation([upperLat, upperLon]);

  return {
    lower,
    upper,
  };
};

export const geohashGenerator = (lat, long) => {
  return geofire.geohashForLocation([lat, long]);
};
