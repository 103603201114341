import { Route, Routes as Switch } from "react-router-dom";
import Admin from "./Admin";
import Party, { PartyEdit, PartyList, PartyView } from "./Party";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route path="/" element={<Admin />}>
          <Route path="/parties" element={<Party />}>
            <Route index element={<PartyList />} />
            <Route path=":id" element={<PartyView />} />
            <Route path="create" element={<PartyEdit />} />
            <Route path="edit/:id" element={<PartyEdit />} />
          </Route>
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
