import { Container } from "@mui/material";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import AppTemplate from "../../components/templates/AppTemplate";
import { AppContext } from "../../providers/app";

const Setting = () => {
  const { user } = useContext(AppContext);

  if (!user) <>Você precisa estar autenticado</>;

  return (
    <AppTemplate hasGoBack={true}>
      <Container
        sx={{
          marginBottom: 2,
        }}
      >
        <Outlet />
      </Container>
    </AppTemplate>
  );
};

export default Setting;
