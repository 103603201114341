import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Avatar, Box, CardHeader, Divider, IconButton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Button from "../../../components/atoms/Button";
import Search from "../../../components/molecules/Search";
import { AppContext } from "../../../providers/app";
import { EffectContext } from "../../../providers/effect";
import { getPlacesByFilter } from "../../../services/places";

const List = () => {
  const { navigateEffect } = useContext(EffectContext);

  const [items, setItems] = useState([]);

  const listPlaces = (name) => {
    getPlacesByFilter(name).then((res) => {
      setItems(res);
    });
  };

  const { setPageTitle } = useContext(AppContext);
  useEffect(() => setPageTitle("Estabelecimentos"), [setPageTitle]);

  useEffect(() => {
    listPlaces("");
  }, []);

  return (
    <div>
      <Box
        sx={{
          marginTop: 2,
        }}
      >
        <Button
          fullWidth
          variant="outlined"
          onClick={() =>
            navigateEffect("/admin/parties/create", "moveToLeftFromRight")
          }
        >
          CADASTRAR NOVO
        </Button>
      </Box>

      <Box
        sx={{
          marginY: 2,
        }}
      >
        <Search placeholder="Nome do estabelecimento" onSubmit={listPlaces} />
      </Box>

      {items.map((item, index) => (
        <Box key={index}>
          <CardHeader
            avatar={
              <Avatar
                sx={{ borderBottom: "3px solid #e3cb00" }}
                aria-label="recipe"
              >
                {item.name[0].toUpperCase()}
              </Avatar>
            }
            title={item.name}
            subheader={`@${item.instagram}`}
            action={
              <Box
                sx={{
                  // width: "110px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <IconButton
                    aria-label="Editar"
                    onClick={() =>
                      navigateEffect(
                        `/admin/parties/edit/${item._id}`,
                        "moveToLeftFromRight"
                      )
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Visualizar"
                    onClick={() =>
                      navigateEffect(
                        `/parties/${item._id}`,
                        "moveToLeftFromRight"
                      )
                    }
                  >
                    <RemoveRedEyeIcon />
                  </IconButton>
                </Box>
              </Box>
            }
          />

          <Divider />
        </Box>
      ))}
    </div>
  );
};

export default List;
