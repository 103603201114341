import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import * as yup from "yup";
import { REQUIRED_FIELD } from "../../constants/error";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const schema = yup
  .object({
    instagram: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(/^(\S+$)/g, "* This field cannot contain only blankspaces"),
    name: yup.string().required(REQUIRED_FIELD),
    description: yup.string(),
  })
  .required();

const FormPartyOperation = ({ initialValues, handleNext, handleBack }) => {
  const [list, setList] = useState([]);

  const [errorText, setErrorText] = useState("");

  const [selectedDay, setSelectedDay] = useState(null);
  const [initialTime, setInitialTime] = useState(null);
  const [finalTime, setFinalTime] = useState(null);

  const initialTimeRef = useRef(null);
  const finalTimeRef = useRef(null);

  const add = () => {
    if (!selectedDay) {
      setErrorText("Selecione o dia");
      return;
    }

    if (!!list.find((item) => item.day === selectedDay)) {
      setErrorText("Esse dia já foi adicionado");
      return;
    }

    if (!initialTime) {
      setErrorText("Selecione o horário inicial");
      return;
    }

    if (!finalTime) {
      setErrorText("Selecione o horário final");
      return;
    }

    setErrorText(null);

    setSelectedDay(null);
    setInitialTime(null);
    setFinalTime(null);

    initialTimeRef.current.value = "";
    finalTimeRef.current.value = "";

    setList((current) => [
      ...current,
      {
        day: selectedDay,
        initialTime,
        finalTime,
      },
    ]);
  };

  const remove = (pos) => {
    const newList = list.filter((item, index) => index != pos);
    setList(newList);
  };

  const onSubmit = () => {
    handleNext({
      operations: list,
    });
  };

  useEffect(() => {
    if (initialValues && initialValues.operations) {
      setList(initialValues.operations);
    }
  }, [initialValues]);

  return (
    <Box
      sx={{
        marginY: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel id="day">Dia</InputLabel>
            <Select
              labelId="day"
              id="day"
              value={selectedDay}
              label="Age"
              onChange={(event) => setSelectedDay(event.target.value)}
            >
              <MenuItem value={null}></MenuItem>
              <MenuItem value="dom">Domingo</MenuItem>
              <MenuItem value="seg">Segunda-feira</MenuItem>
              <MenuItem value="ter">Terça-feira</MenuItem>
              <MenuItem value="qua">Quarta-feira</MenuItem>
              <MenuItem value="qui">Quinta-feira</MenuItem>
              <MenuItem value="sex">Sexta</MenuItem>
              <MenuItem value="sab">Sábado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="time"
            label="Horário inicial"
            fullWidth
            value={initialTime}
            onChange={(event) => setInitialTime(event.target.value)}
            inputRef={initialTimeRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccessTimeIcon
                    sx={{
                      width: "20px",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="time"
            label="Horário final"
            fullWidth
            value={finalTime}
            onChange={(event) => setFinalTime(event.target.value)}
            inputRef={finalTimeRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccessTimeIcon
                    sx={{
                      width: "20px",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="text" fullWidth onClick={add}>
            Adicionar
          </Button>
        </Grid>

        {errorText && (
          <Box
            sx={{
              marginX: 2,
            }}
          >
            <FormHelperText error={true}>{errorText}</FormHelperText>
          </Box>
        )}
      </Grid>

      <TableContainer
        sx={{
          marginTop: 2,
        }}
      >
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Dia</TableCell>
              <TableCell align="right">Início</TableCell>
              <TableCell align="right">Fim</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.day}
                </TableCell>
                <TableCell align="right">{row.initialTime}</TableCell>
                <TableCell align="right">{row.finalTime}</TableCell>
                <TableCell align="right">
                  <IconButton size="small" onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box marginTop={2}>
        <Button
          variant="outlined"
          sx={{ mt: 1, mr: 1 }}
          disabled={list.length === 0}
          onClick={onSubmit}
        >
          CONTINUAR
        </Button>
        <Button
          type="button"
          variant="text"
          sx={{ mt: 1, mr: 1 }}
          onClick={handleBack}
        >
          VOLTAR
        </Button>
      </Box>
    </Box>
  );
};

export default FormPartyOperation;
