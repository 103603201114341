import { Box, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useContext } from "react";
import { EffectContext } from "../../providers/effect";

const EditableMenu = ({ title, list }) => {
  const { navigateEffect } = useContext(EffectContext);

  return (
    <>
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 1,
        }}
      >
        <Typography fontSize={14}>{title}</Typography>
      </Box>
      {list.map((item, index) => (
        <Box
          sx={{
            display: "flex",
            paddingY: 1.2,
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
          onClick={() => {
            navigateEffect("/" + item.path, "moveToLeftFromRight");
          }}
          key={index}
        >
          <Box
            sx={{
              flex: 1,
              color: "#e9e9e9",
              marginRight: 2,
            }}
          >
            {item.label}
          </Box>
          <Box
            sx={{
              display: "flex",
              cursor: "pointer",
            }}
          >
            {item.value && (
              <Typography
                color="#e9e9e9"
                sx={
                  (item.label) && {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "150px",
                  }
                }
              >
                {item.value}
              </Typography>
            )}
            {!item.value && item.helperText && (
              <Typography color="#d1d1d1">{item.helperText}</Typography>
            )}

            {!item.disableArrow && (
              <NavigateNextIcon
                sx={{ color: !item.value ? "#d1d1d1" : "#e9e9e9" }}
              />
            )}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default EditableMenu;
