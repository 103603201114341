import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, InputAdornment } from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { REQUIRED_FIELD } from "../../constants/error";
import { blockSpaces } from "../../utils/string";
import Input from "../molecules/Input";

const schema = yup
  .object({
    instagram: yup
      .string()
      .required(REQUIRED_FIELD)
      .matches(/^(\S+$)/g, "* This field cannot contain only blankspaces"),
    name: yup.string().required(REQUIRED_FIELD),
    description: yup.string(),
  })
  .required();

const FormPartyInfo = ({ initialValues, handleNext, handleBack }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      instagram: initialValues.instagram || "",
      name: initialValues.name || "",
      description: "",
    },
  });

  const onSubmit = (data) => {
    handleNext(data);
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{ marginY: 2 }}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Input
            type="text"
            name="instagram"
            control={control}
            label="Instagram"
            errors={errors.instagram}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">@</InputAdornment>
              ),
            }}
            onChange={({ target }) => {
              setValue("instagram", blockSpaces(target.value));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            type="text"
            name="name"
            control={control}
            label="Nome do estabelecimento"
            errors={errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            type="text"
            name="description"
            control={control}
            label="Descrição"
            errors={errors.description}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>

      <Box marginTop={2}>
        <Button type="submit" variant="outlined" sx={{ mt: 1, mr: 1 }}>
          CONTINUAR
        </Button>
      </Box>
    </Box>
  );
};

export default FormPartyInfo;
