import { useContext, useEffect } from "react";
import AppTemplate from "../components/templates/AppTemplate";
import { AppContext } from "../providers/app";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

const Notifications = () => {
  const { setPageTitle } = useContext(AppContext);

  useEffect(() => setPageTitle("Notificações"), [setPageTitle]);

  return (
    <AppTemplate>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem alignItems="flex-start">
          {/* <ListItemAvatar>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
          </ListItemAvatar> */}
          <ListItemText
            primary="Brunch this weekend?"
            secondary={
              <>{"I'll be in your neighborhood doing errands this…"}</>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </List>
    </AppTemplate>
  );
};

export default Notifications;
