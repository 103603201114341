import { Box, Button } from "@mui/material";
import { useContext, useEffect } from "react";
import Input from "../../components/molecules/Input";
import { AppContext } from "../../providers/app";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUser } from "../../services/user";
import { auth } from "../../config/firebase";
import { updateProfile } from "firebase/auth";
import { REQUIRED_FIELD } from "../../constants/error";
import { blockSpacesOnStart } from "../../utils/string";
import { useState } from "react";
import { EffectContext } from "../../providers/effect";

const schema = yup
  .object({
    displayName: yup.string().required(REQUIRED_FIELD),
  })
  .required();

const UpdateName = () => {
  const { setPageTitle, user, setUser } = useContext(AppContext);
  useEffect(() => setPageTitle("Editar nome"), [setPageTitle]);
  const { navigateEffect } = useContext(EffectContext);

  const [isSubmitted, setIsSubmitted] = useState();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      displayName: "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);

    Promise.all([
      updateProfile(auth.currentUser, {
        displayName: data.displayName,
      }),
      updateUser(auth.currentUser.uid, {
        displayName: data.displayName,
      }),
    ])
      .then((values) => {
        setUser((current) => ({
          ...current,
          displayName: data.displayName,
        }));

        navigateEffect('/settings/update-profile', "moveToLeftFromRight");
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  useEffect(() => {
    user && setValue("displayName", user.displayName);
  }, [user]);

  return (
    <div>
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Input
          type="text"
          name="displayName"
          control={control}
          label="Nome"
          errors={errors.displayName}
          margin="normal"
          onChange={({ target }) => {
            setValue("displayName", blockSpacesOnStart(target.value));
          }}
        />

        <Box sx={{ mt: 2, mb: 2 }}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            disabled={isSubmitted}
          >
            SALVAR
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default UpdateName;
