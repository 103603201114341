import { Link, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { EffectContext } from "../../providers/effect";

const WithoutReservations = () => {
  const { navigateEffect } = useContext(EffectContext);

  return (
    <Paper
      sx={{
        padding: 2,
        marginTop: 2,
      }}
    >
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography fontWeight={700}>
          Você ainda não fez nenhuma reserva.
        </Typography>
        <Typography fontSize={12} marginY={1}>
          <Link
            onClick={() => {
              navigateEffect("/home", "moveToLeftFromRight");
            }}
          >
            Clique aqui
          </Link>{" "}
          e veja os melhores rolês em sua região
        </Typography>
      </Box>
    </Paper>
  );
};

export default WithoutReservations;
