import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import NightlifeIcon from "@mui/icons-material/Nightlife";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { NOT_AUTH_PAGES } from "../../constants/pages";
import { AppContext } from "../../providers/app";
import { EffectContext } from "../../providers/effect";
import { checkIfIncludes } from "../../utils/array";
import LoginIcon from '@mui/icons-material/Login';

const Footer = () => {
  let location = useLocation();

  const { user } = useContext(AppContext);
  const { navigateEffect } = useContext(EffectContext);

  const routesFooter = [
    { name: "Roles", icon: <LocalFireDepartmentIcon />, path: "/" },
    { name: "Pessoas", icon: <PersonSearchIcon />, path: "/meet" },
    // { name: "Reservas", icon: <NightlifeIcon />, path: "/reservations" },
  ];

  if (user) {
    routesFooter.push({
      name: "Perfil",
      icon: <AccountCircleIcon />,
      path: `/${
        user
          ? `${user.username ? `@${user.username}` : `u/${user.uid}`}`
          : "no-account"
      }`,
    });
  } else {
    routesFooter.push({
      name: "Entrar",
      icon: <LoginIcon />,
      path: `/login`,
    });
  }

  const [current, setCurrent] = useState(
    routesFooter.map(
      (route, index) => route.path === location.pathname && index
    )
  );

  if (checkIfIncludes(NOT_AUTH_PAGES, location.pathname)) return <></>;

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={location.pathname}
        onChange={(event, newValue) => {
          let next = routesFooter.map(
            (route, index) => route.path === newValue && index
          );
          let preset =
            next > current ? "moveToLeftFromRight" : "moveToRightFromLeft";

          setCurrent(next);
          navigateEffect(newValue, preset);
        }}
      >
        {routesFooter.map((route, index) => (
          <BottomNavigationAction
            label={route.title}
            icon={route.icon}
            value={route.path}
            key={index}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default Footer;
