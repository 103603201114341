import axios from "axios";

const API_URL = "https://api.lordsoft.com.br";

export const getAddress = async (zipcode, token) => {
  const url = `${API_URL}/address.php`;
  const response = await axios.get(url, {
    params: {
      cep: zipcode,
      token,
    },
  });
  return response.data;
};

export const getGeolocation = async (address, token) => {
  const addressText = `${address.zipcode} ${address.street}, ${address.number} - ${address.complement} - ${address.district} - ${address.city} - ${address.state}`;

  const url = `${API_URL}/coords.php?token=${token}&address=${addressText}`;
  const response = await axios.get(url, {
    params: {
      address,
      token,
    },
  });
  return response.data;
};
