import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { getMusicalStyles } from "../../services/musical";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const categorias = [
  { key: "rock", value: "Rock" },
  { key: "pop", value: "Pop" },
  { key: "sertanejo", value: "Sertanejo" },
  { key: "funk", value: "Funk" },
  { key: "eletronica", value: "Eletrônica" },
  { key: "sertanejouniversitario", value: "Sertanejo Universitário" },
  { key: "mpb", value: "MPB" },
  { key: "bossanova", value: "Bossanova" },
  { key: "jazz", value: "Jazz" },
  { key: "pagode", value: "Pagode" },
  { key: "forro", value: "Forró" },
  { key: "axe", value: "Axé" },
  { key: "lgbtqia", value: "LGBTQIA+" }
];

const FormPartyMusicalStyle = ({ initialValues, handleNext, handleBack }) => {
  const [list, setList] = useState([]);
  const [musicalStyles, setMusicalStyles] = useState([...categorias]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setList(typeof value === "string" ? value.split(",") : value);
  };

  const onSubmit = () => {
    handleNext({
      musicalStyles: musicalStyles.filter((item) => list.includes(item.key)),
    });
  };

  useEffect(() => {
    if (initialValues && initialValues.musicalStyles) {
      setList(initialValues.musicalStyles.map((item) => item.key));
    }
  }, [initialValues]);

  return (
    <Box
      sx={{
        marginY: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="musicalStyles">
              Selecione as categorias
            </InputLabel>
            <Select
              labelId="musicalStyles"
              id="musicalStyles"
              multiple
              value={list}
              onChange={handleChange}
              input={<OutlinedInput label="Selecione as categorias" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {musicalStyles.map((style) => (
                <MenuItem key={style.key} value={style.key}>
                  <Checkbox checked={list.indexOf(style.key) > -1} />
                  <ListItemText primary={style.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box marginTop={2}>
        <Button
          variant="outlined"
          sx={{ mt: 1, mr: 1 }}
          disabled={list.length === 0}
          onClick={onSubmit}
        >
          CONTINUAR
        </Button>
        <Button
          type="button"
          variant="text"
          sx={{ mt: 1, mr: 1 }}
          onClick={handleBack}
        >
          VOLTAR
        </Button>
      </Box>
    </Box>
  );
};

export default FormPartyMusicalStyle;
