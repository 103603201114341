import { Box } from "@mui/material";

const Story = ({ image }) => {
  return (
    <Box
      sx={{
        width: "40px",
        height: "110px",
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: 2,
        marginRight: "5px",
        border: '2px solid #ba68c'
      }}
    ></Box>
  );
};

export default Story;
