import { Box, Button } from "@mui/material";
import { useContext, useEffect } from "react";
import Input from "../../components/molecules/Input";
import { AppContext } from "../../providers/app";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateEmail } from "firebase/auth";
import { auth } from "../../config/firebase";
import { EffectContext } from "../../providers/effect";

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

const UpdateEmail = () => {
  const { user, setPageTitle } = useContext(AppContext);
  useEffect(() => setPageTitle("Alterar e-mail"), [setPageTitle]);
  const { navigateEffect } = useContext(EffectContext);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: user.email,
    },
  });

  const onSubmit = (data) => {
    updateEmail(auth.currentUser, data.email)
      .then((res) => {
        navigateEffect("/settings", "moveToLeftFromRight");
      })
      .catch((err) => {
      });
  };

  return (
    <div>
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          type="email"
          name="email"
          control={control}
          label="E-mail"
          autoComplete="email"
          autoFocus
          errors={errors.email}
          margin="normal"
        />

        <Box sx={{ mt: 2, mb: 2 }}>
          <Button type="submit" fullWidth variant="outlined">
            ALTERAR
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default UpdateEmail;
