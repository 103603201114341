import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../config/firebase";

const COLLECTION = "users";

export const updateUser = async (id, data) => {
  return setDoc(
    doc(db, COLLECTION, id),
    {
      ...data,
    },
    {
      merge: true,
    }
  );
};

export const getUserById = async (id) => {
  const docRef = doc(db, COLLECTION, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      _id: docSnap.id,
      ...docSnap.data(),
    };
  } else {
    return null;
  }
};

export const getUserByUsername = async (username) => {
  const userRef = collection(db, COLLECTION);

  const q = query(userRef, where("username", "==", username));

  const documentSnapshots = await getDocs(q);

  if (documentSnapshots.docs.length > 0) {
    const docSnap = documentSnapshots.docs[0];
    return {
      _id: docSnap.id,
      ...docSnap.data(),
    };
  }

  return null;
};

export const getUsers = async () => {
  const userRef = collection(db, COLLECTION);

  const q = query(userRef, orderBy("createAt"));

  const documentSnapshots = await getDocs(q);

  return documentSnapshots.docs.map((docSnap) => ({
    _id: docSnap.id,
    ...docSnap.data(),
  }));
};
