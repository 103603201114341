import { CssBaseline } from "@mui/material";
import { purple } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "./components/molecules/Footer";
import Header from "./components/molecules/Header";
import AppProvider from "./providers/app";
import EffectProvider from "./providers/effect";
import Routes from "./Routes";
import "./styles/global.css";

import "react-image-crop/dist/ReactCrop.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: purple[300],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppProvider>
        <EffectProvider>
          <Header />
          <Routes />
          <Footer />
        </EffectProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
