import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import WithoutReservations from "../components/molecules/WithoutReservations";
import AppTemplate from "../components/templates/AppTemplate";
import { AppContext } from "../providers/app";
const Reservations = () => {
  const { user, setPageTitle } = useContext(AppContext);

  const [places, setPlaces] = useState([]);
  const [userLocation, setUserLocation] = useState();

  useEffect(() => setPageTitle("Reservas"), [setPageTitle]);

  return (
    <Box>
      <AppTemplate>
        <WithoutReservations />
      </AppTemplate>
    </Box>
  );
};

export default Reservations;
