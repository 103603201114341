import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect } from "react";
import { AppContext } from "../../providers/app";
import { EffectContext } from "../../providers/effect";
import Link from "../../components/atoms/Link";

const list = [
  { title: "Editar perfil", path: "settings/update-profile" },
  { title: "Alterar e-mail", path: "settings/update-email" },
  { title: "Alterar senha", path: "settings/update-password" },
  // { title: "Alterar telefone", path: "settings/update-phone" },
  { title: "Privacidade", path: "settings/privacy" },
  // { title: "Bloqueados", path: "settings/blocks" },
  // { title: "Histórico de login", path: "settings/history" },
];

const letsList = [
  { title: "Cadastrar estabelecimento", path: "settings/update-profile" },
  { title: "Nossa equipe", path: "staff" },
  { title: "Termos de uso", path: "settings/update-aaa" },
  { title: "Política de privacidade", path: "settings/update-ddd" },
];

const adminList = [{ title: "Estabelecimentos", path: "parties" }];

const Home = () => {
  const { navigateEffect } = useContext(EffectContext);

  const { user, setPageTitle } = useContext(AppContext);
  useEffect(() => setPageTitle("Ajustes"), [setPageTitle]);

  return (
    <div>
      <Paper sx={{ width: "100%", maxWidth: "100%", marginTop: 2 }}>
        <MenuList>
          {list.map((item) => (
            <MenuItem
              onClick={() => {
                navigateEffect("/" + item.path, "moveToRightFromLeft");
              }}
              key={item.path}
            >
              <ListItemText>{item.title}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>

        <Divider />
      </Paper>

      <Box sx={{ marginTop: 2 }}>
        <Box sx={{ marginY: 1, textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: 14,
            }}
          >
            LetsFun
          </Typography>
        </Box>
        <Paper>
          <MenuList>
            {letsList.map((item) => (
              <MenuItem
                onClick={() => {
                  navigateEffect("/" + item.path, "moveToRightFromLeft");
                }}
                key={item.path}
              >
                <ListItemText>{item.title}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>

          <Divider />
        </Paper>
      </Box>

      {user && user.isAdmin && (
        <Box sx={{ marginTop: 2 }}>
          <Box sx={{ marginY: 1, textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: 14,
              }}
            >
              Administração
            </Typography>
          </Box>
          <Paper>
            <MenuList>
              {adminList.map((item) => (
                <MenuItem
                  onClick={() => {
                    navigateEffect("/admin/" + item.path, "moveToRightFromLeft");
                  }}
                  key={item.path}
                >
                  <ListItemText>{item.title}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Box>
      )}

      <Box
        sx={{
          marginTop: 3,
          marginBottom: 2,
          textAlign: "center",
        }}
      >
        <Link to="/logout">Desconectar da conta</Link>
      </Box>
    </div>
  );
};

export default Home;
