import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { FormHelperText } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Button from "../components/atoms/Button";
import Copyright from "../components/atoms/Copyright";
import Link from "../components/atoms/Link";
import Input from "../components/molecules/Input";
import InputPassword from "../components/molecules/InputPassword";
import { auth } from "../config/firebase";
import { EffectContext } from "../providers/effect";

const schema = yup
  .object({
    email: yup.string().email().required("Digite seu e-mail"),
  })
  .required();

const RecoverPassword = () => {
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { navigateEffect } = useContext(EffectContext);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);

    sendPasswordResetEmail(auth, data.email, data.password)
      .then((res) => {
        navigateEffect("/login", "carouselToRight");
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Container maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recuperar senha
          </Typography>

          <FormHelperText error={!!error}>{error}</FormHelperText>

          <Box
            component="form"
            noValidate
            sx={{ mt: 1, width: "100%" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              type="email"
              name="email"
              control={control}
              label="Digite seu e-mail"
              autoComplete="email"
              errors={errors.email}
              margin="normal"
              autoFocus
            />

            <Box sx={{ mt: 2, mb: 2 }}>
              <Button type="submit" fullWidth disabled={isSubmitted}>
                Recuperar senha
              </Button>
            </Box>
            <Grid container>
              <Grid
                item
                sx={{
                  textAlign: "center",
                  width: "100%",
                  marginTop: 2,
                }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    navigateEffect("/login", "carouselToRight");
                  }}
                >
                  Lembrei minha senha
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </Box>
  );
};

export default RecoverPassword;
