import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { FormHelperText } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../components/atoms/Button";
import Copyright from "../components/atoms/Copyright";
import Input from "../components/molecules/Input";
import InputPassword from "../components/molecules/InputPassword";
import { auth } from "../config/firebase";
import { EffectContext } from "../providers/effect";
import { updateUser } from "../services/user";
import { blockSpaces, blockSpacesOnStart } from "../utils/string";

const schema = yup
  .object({
    displayName: yup.string().required("Digite seu nome"),
    email: yup.string().email().required("Digite seu e-mail"),
    password: yup.string().required("Digite sua senha"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Senhas não conferem")
      .required("Digite novamente sua senha"),
  })
  .required();

const Register = () => {
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { navigateEffect } = useContext(EffectContext);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);

    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then((res) => {
        updateProfile(res.user, {
          displayName: data.displayName,
        });

        updateUser(res.user.id, {
          displayName: data.displayName,
          createAt: serverTimestamp(),
        });

        navigateEffect("/login", "carouselToRight");
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Crie sua conta
        </Typography>

        <FormHelperText error={!!error}>{error}</FormHelperText>

        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            name="displayName"
            label="Nome"
            control={control}
            errors={errors.displayName}
            margin="normal"
            onChange={({ target }) => {
              setValue("displayName", blockSpacesOnStart(target.value));
            }}
          />

          <Input
            type="email"
            name="email"
            control={control}
            label="E-mail"
            autoComplete="email"
            errors={errors.email}
            margin="normal"
            onChange={({ target }) => {
              setValue("email", blockSpaces(target.value));
            }}
          />
          <InputPassword
            name="password"
            control={control}
            label="Senha"
            errors={errors.password}
            margin="normal"
          />
          <InputPassword
            name="confirmPassword"
            control={control}
            label="Confirmar senha"
            errors={errors.confirmPassword}
            margin="normal"
          />

          <Box sx={{ mt: 2, mb: 2 }}>
            <Button type="submit" fullWidth disabled={isSubmitted}>
              Cadastrar
            </Button>
          </Box>
          <Grid container>
            <Grid
              item
              sx={{
                textAlign: "center",
                width: "100%",
                marginTop: 2,
              }}
            >
              <Button
                variant="text"
                onClick={() => {
                  navigateEffect("/login", "carouselToRight");
                }}
              >
                Já tenho conta
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

export default Register;
