import { Box } from "@mui/material";

const AddImage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "120px",
        borderRadius: 1,
        marginRight: "5px",
        border: "2px solid #363636",
        color: "#808080",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        fontSize: 12
      }}
    >
      ADICIONAR IMAGEM
    </Box>
  );
};

export default AddImage;
