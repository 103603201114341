import {
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import LocationInfo from "./LocationInfo";

const locations = [
  {
    title: "São Paulo - Centro",
    lat: -23.54275760245825,
    long: -46.63281991723209,
  },
  {
    title: "São Paulo - Zona Leste",
    lat: -23.544825778848168,
    long: -46.455818780877394,
  },
  {
    title: "São Paulo - Zona Norte",
    lat: -23.479412036764714,
    long: -46.670918831184544,
  },
  {
    title: "São Paulo - Zona Oeste",
    lat: -23.5710779945348,
    long: -46.70066137064196,
  },
  {
    title: "São Paulo - Zona Sul",
    lat: -23.653262226562756,
    long: -46.65908120130566,
  },
  {
    title: "São Caetano do Sul",
    lat: -23.615053509959925,
    long: -46.56834125439742,
  },
  { title: "Santo André", lat: -23.66576870386446, long: -46.53409569966743 },
];

const KEY = "selected-location";

const SelectLocation = ({ userLocation, setUserLocation }) => {
  const [location, setLocation] = useState("current");

  const getCurrentLocation = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        if (location) {
          setUserLocation({
            lat: location.coords.latitude,
            long: location.coords.longitude,
          });
        }
      });
    }
  };

  const handleChange = ({ target }) => {
    const { value } = target;
    setLocation(value);

    if (value === "current") {
      setUserLocation(null);
      getCurrentLocation();
      localStorage.setItem(KEY, "current");
    } else {
      const location = locations.find((item, index) => index == value);
      localStorage.setItem(KEY, "" + value);

      if (location) {
        setUserLocation({
          lat: location.lat,
          long: location.long,
        });
      }
    }
  };

  useEffect(() => {
    const value = localStorage.getItem(KEY);

    if (value && value !== undefined) {
      setLocation(value);
      handleChange({
        target: { value },
      });
    } else {
      getCurrentLocation();
    }
  }, []);

  return (
    <>
      <FormControl
        fullWidth
        size="small"
        sx={{
          marginY: 2,
        }}
      >
        <InputLabel id="location">Seleciona localização</InputLabel>
        <Select
          labelId="location"
          id="location"
          value={location}
          label="Selecionar localização"
          onChange={handleChange}
        >
          <MenuItem value="current">
            <em>Localização atual</em>
          </MenuItem>
          {locations.map((location, index) => (
            <MenuItem value={index} key={index}>
              {location.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {!userLocation && <LocationInfo />}
    </>
  );
};

export default SelectLocation;
