import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Input from "../../components/molecules/Input";
import { auth } from "../../config/firebase";
import { MAX_200 } from "../../constants/error";
import { AppContext } from "../../providers/app";
import { EffectContext } from "../../providers/effect";
import { updateUser } from "../../services/user";

const schema = yup
  .object({
    description: yup.string().max(200, MAX_200),
  })
  .required();

const UpdateDescription = () => {
  const { setPageTitle, user, setUser } = useContext(AppContext);
  useEffect(() => setPageTitle("Editar descrição"), [setPageTitle]);
  const { navigateEffect } = useContext(EffectContext);

  const [isSubmitted, setIsSubmitted] = useState();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitted(true);

    updateUser(auth.currentUser.uid, {
      description: data.description,
    })
      .then((values) => {
        setUser((current) => ({
          ...current,
          description: data.description,
        }));
        navigateEffect('/settings/update-profile', "moveToLeftFromRight");
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  useEffect(() => {
    user && setValue("description", user.description);
  }, [user]);

  return (
    <div>
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Input
          type="text"
          name="description"
          control={control}
          label="Descrição"
          placeholder="Digite aqui algo sobre você..."
          errors={errors.description}
          margin="normal"

          rows={4}
          multiline
        />

        <Box sx={{ mt: 2, mb: 2 }}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            disabled={isSubmitted}
          >
            SALVAR
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default UpdateDescription;
