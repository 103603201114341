import {
  Button,
  Card,
  CardActions,
  CardContent,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FormPartyAddress from "../../../components/organisms/FormPartyAddress";
import FormPartyContact from "../../../components/organisms/FormPartyContact";
import FormPartyInfo from "../../../components/organisms/FormPartyInfo";
import FormPartyMusicalStyle from "../../../components/organisms/FormPartyMusicalStyle";
import FormPartyOperation from "../../../components/organisms/FormPartyOperation";
import FormPartyPreview from "../../../components/organisms/FormPartyPreview";
import { AppContext } from "../../../providers/app";
import { EffectContext } from "../../../providers/effect";
import {
  createPlace,
  getPlaceById,
  updatePlace
} from "../../../services/places";

const steps = [
  {
    label: "Informações",
    component: FormPartyInfo,
  },
  {
    label: "Endereço",
    component: FormPartyAddress,
  },
  {
    label: "Contato",
    component: FormPartyContact,
  },
  {
    label: "Funcionamento",
    component: FormPartyOperation,
  },
  {
    label: "Categorias",
    component: FormPartyMusicalStyle,
  },
  {
    label: "Confirmação",
    component: FormPartyPreview,
  },
];

const Edit = () => {
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let { id } = useParams();

  const { navigateEffect } = useContext(EffectContext);
  const { user, setPageTitle } = useContext(AppContext);
  useEffect(
    () => setPageTitle(`${id ? "Editar" : "Cadastrar"} estabelecimento`),
    [id, setPageTitle]
  );

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (data) => {
    setValues((current) => ({ ...current, ...data }));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    if (!id) {
      createPlace(values).then((res) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      });
    } else {
      updatePlace(id, values).then((res) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      });
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getPlaceById(id)
        .then((res) => {
          setValues(res);
        })
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  return (
    <div>
      {!isLoading ? (
        <>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  onClick={() =>
                    (activeStep > index || id) && setActiveStep(index)
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <Typography>
                    <step.component
                      initialValues={values}
                      handleNext={handleNext}
                      handleBack={handleBack}
                      handleSubmit={handleSubmit}
                    />
                  </Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length && (
            <>
              <Card sx={{ width: "100%", marginTop: 2 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {id ? "Editado com sucesso" : "Criado com sucesso"}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() =>
                      navigateEffect("/admin/parties", "moveToRightFromLeft")
                    }
                  >
                    IR PARA LISTAGEM
                  </Button>
                </CardActions>
              </Card>
            </>
          )}
        </>
      ) : (
        <>Carregando...</>
      )}
    </div>
  );
};

export default Edit;
