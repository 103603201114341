import { useContext, useEffect } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";
import { useNavigate } from "react-router";
import { AppContext } from "../providers/app";

const Logout = () => {
  let navigate = useNavigate();

  const { user, setUser } = useContext(AppContext);

  useEffect(() => {
    signOut(auth)
      .then(() => {
        navigate("/login");
        setUser(null);
      })
      .catch((error) => {});
  }, []);

  return <></>;
};

export default Logout;
