import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const Input = (props) => {
  const { name, control, errors, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          helperText={errors && errors.message}
          error={!!errors}
          {...rest}
        />
      )}
    />
  );
};

export default Input;
