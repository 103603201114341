import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GridProfile from "../../components/molecules/GridProfile";
import Loading from "../../components/molecules/Loading";
import SocialNetworks from "../../components/molecules/SocialNetworks";
import ProfileProgress from "../../components/organisms/ProfileProgress";
import { AppContext } from "../../providers/app";
import { EffectContext } from "../../providers/effect";
import { getUserById, getUserByUsername } from "../../services/user";

const View = () => {
  let { username, id } = useParams();

  const [profileUser, setProfileUser] = useState(null);
  const [isLoading, setSetIsLoading] = useState(true);
  const { navigateEffect } = useContext(EffectContext);

  const { setPageTitle, user } = useContext(AppContext);

  const findUserByUsername = () => {
    setSetIsLoading(true);
    getUserByUsername(username)
      .then((res) => {
        if (res) setProfileUser(res);
      })
      .finally(() => {
        setSetIsLoading(false);
      });
  };

  const findUserById = () => {
    setSetIsLoading(true);
    getUserById(id)
      .then((res) => {
        if (res) setProfileUser(res);
      })
      .finally(() => {
        setSetIsLoading(false);
      });
  };

  useEffect(() => {
    username && findUserByUsername();
  }, [username]);

  useEffect(() => {
    id && findUserById();
  }, [id]);

  useEffect(() => {
    if (profileUser && profileUser.username) {
      setPageTitle("@" + profileUser.username);
    }
  }, [setPageTitle, profileUser]);

  if (isLoading) return <Loading />;

  if (!profileUser) return <></>;

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          marginTop: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            alt={profileUser.displayName}
            src={profileUser.photoURL}
            sx={{
              width: "100px",
              height: "100px",
            }}
          />
        </Box>
        <Box
          sx={{
            marginTop: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
            }}
          >
            {profileUser.displayName}
          </Typography>
        </Box>
        <Box sx={{ marginTop: 1 }}>
          <Typography fontSize={12}>{profileUser.description}</Typography>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          {user && <SocialNetworks user={profileUser} />}
        </Box>
      </Box>

      <Container maxWidth="sm">
        {user && user.uid === profileUser._id && (
          <Grid container spacing={1} marginTop={2} marginBottom={3}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  navigateEffect("/settings/update-profile", "roomToBottom");
                }}
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#363636",
                }}
                size="small"
              >
                Editar perfil
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  navigateEffect("/settings", "roomToBottom");
                }}
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#363636",
                }}
                size="small"
              >
                Configurações
              </Button>
            </Grid>
          </Grid>
        )}

        {user && user.uid === profileUser._id && (
          <Box
            sx={{
              marginBottom: 3,
            }}
          >
            <ProfileProgress
              params={{
                user,
                contents: profileUser.contents ? profileUser.contents : null,
              }}
            />
          </Box>
        )}

        <Box>
          <GridProfile
            images={profileUser.contents ? profileUser.contents : []}
            displayName={profileUser.displayName}
            setProfileUser={setProfileUser}
            isAuthUser={user && user.uid === profileUser._id}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default View;
