import { Box, IconButton } from "@mui/material";
import twitterIcon from "../../assets/images/twitter.png";
import instagramIcon from "../../assets/images/instagram.png";
import tiktokIcon from "../../assets/images/tiktok.png";
import whatsappIcon from "../../assets/images/whatsapp.png";

const SocialNetworks = ({ user }) => {
  return (
    <Box>
      {user.instagram && (
        <a
          target="_blank"
          href={`https://instagram.com/${user.instagram}`}
          rel="noreferrer"
        >
          <IconButton>
            <img src={instagramIcon} alt="Twitter" width={30} height={30} />
          </IconButton>
        </a>
      )}
      {user.tiktok && (
        <a
          target="_blank"
          href={`https://tiktok.com/@${user.instagram}`}
          rel="noreferrer"
        >
          <IconButton>
            <img src={tiktokIcon} alt="TikTok" width={30} height={30} />
          </IconButton>
        </a>
      )}
      {user.twitter && (
        <a
          target="_blank"
          href={`https://twitter.com/${user.instagram}`}
          rel="noreferrer"
        >
          <IconButton>
            <img src={twitterIcon} alt="Twitter" width={30} height={30} />
          </IconButton>
        </a>
      )}
      {user.whatsapp && (
        <a
          target="_blank"
          href={`https://wa.me/55${user.whatsapp}`}
          rel="noreferrer"
        >
          <IconButton>
            <img src={whatsappIcon} alt="Whatsapp" width={30} height={30} />
          </IconButton>
        </a>
      )}
    </Box>
  );
};

export default SocialNetworks;
