import { Box, Button } from "@mui/material";
import { serverTimestamp } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import CropImage from "../../components/organisms/CropImage";
import { auth } from "../../config/firebase";
import { AppContext } from "../../providers/app";
import { EffectContext } from "../../providers/effect";
import { findDownloadUrl, uploadFile } from "../../services/file";
import { updateUser } from "../../services/user";
var md5 = require("md5");

const AddPhoto = () => {
  const { setPageTitle, user, setUser } = useContext(AppContext);
  useEffect(() => setPageTitle("Adicionar nova imagem"), [setPageTitle]);
  const { navigateEffect } = useContext(EffectContext);

  const [isSubmitted, setIsSubmitted] = useState();
  const [image, setImage] = useState(null);

  const onSubmit = () => {
    setIsSubmitted(true);

    const ramdom = md5(user.uid + new Date().toISOString());

    const path = `userPhotos/${ramdom}`;

    const contents = user.contents || [];

    uploadFile(image, path).then(async (res) => {
      const photoURL = await findDownloadUrl(path);

      contents.push(photoURL);

      updateUser(auth.currentUser.uid, {
        contents,
      })
        .then(() => {
          setUser((current) => ({
            ...current,
            contents,
          }));

          navigateEffect(-1, "moveToLeftFromRight");
        })
        .finally(() => {
          setIsSubmitted(false);
        });
    });
  };

  if (user && user.contents && user.contents.length > 6) {
    return <>Limite atingido</>;
  }

  return (
    <div>
      <Box sx={{ mt: 2, mb: 2 }}>
        <CropImage setImage={setImage} />

        <Box marginTop={3} />

        <Button
          type="submit"
          fullWidth
          variant="outlined"
          disabled={isSubmitted || !image}
          onClick={onSubmit}
        >
          SALVAR
        </Button>
      </Box>
    </div>
  );
};

export default AddPhoto;
