import { DAYS_OF_WEEK } from "../constants/days";

export const showDays = (days) => {
  if (days.length === 1) {
    return DAYS_OF_WEEK.find((day) => day.key === days[0]).value.replace(
      "-Feira",
      ""
    );
  }

  if (days.length === 2) {
    return order(
      DAYS_OF_WEEK.filter((day) => days.includes(day.key)).map((day) =>
        day.value.replace("-Feira", "")
      )
    ).join(" e ");
  }

  if (days.length >= 2 && days.length < 7) {
    const list = order(
      DAYS_OF_WEEK.filter((day) => days.includes(day.key)).map(
        (day) => day.key[0].toUpperCase() + day.key.substr(1)
      )
    );

    return (
      list.filter((day, index) => index < list.length - 1).join(", ") +
      " e " +
      list[list.length - 1]
    );
  }

  if (days.length === 7) {
    return "Todos os dias";
  }

  return "";
};

export const showDay = (days) => {
  if (days.length === 1) {
    return DAYS_OF_WEEK.find((day) => day.key === days[0]).value.replace(
      "-Feira",
      ""
    );
  }
  return "";
};

const order = (list) =>
  list.sort(function (a, b) {
    return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
  });
