import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../config/firebase";

export const uploadFile = (file, filename) => {
  const storageRef = ref(storage, filename);

  return uploadBytes(storageRef, file);
};

export const findDownloadUrl = (filename) => {
  return getDownloadURL(ref(storage, filename));
};
